<template>
  <Toast></Toast>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '450px' }"
    header="Registrar Movimiento de Caja"
    :modal="true"
    @hide="ocultarModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-2">
          <label for=""><strong>ID CAJA:</strong></label>
          <InputText id="id" v-model="data_mov_caja.id" disabled="true" />
        </div>
        <div class="field col-12 md:col-4">
          <label for=""
            ><strong>TIPO MOVIMIENTO: </strong>
            <span class="p-invalid">*</span></label
          >
          <Dropdown
            autofocus
            v-model="tipo_mov"
            :options="tp_movimientos"
            optionLabel="label"
            placeholder="Seleccione tipo Movimiento"
          />
          <small class="p-invalid" v-if="errors.tipo_mov">{{
            errors.tipo_mov[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <label for=""
            ><strong>AUTORIZADO POR: </strong>
            <span class="p-invalid">*</span></label
          >
          <InputText v-model="autorizado_por" />
          <small class="p-invalid" v-if="errors.autorizado_por">{{
            errors.autorizado_por[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="metodo_pago"
            ><strong>M&Eacute;TODO DE PAGO:</strong>
            <small class="p-invalid">*</small></label
          >
          <Dropdown
            id="metodo_pago"
            v-model="metodo_pagoSelect"
            :options="metodo_pagos"
            optionLabel="nombre"
            class="w-full"
            optionValue="id"
          />
        </div>
        <div class="field col-12 md:col-4" v-if="metodo_pagoSelect != 1">
          <label for=""><strong>MONTO:</strong></label>
          <InputNumber
            v-model="importe_metodos_pagos"
            :minFractionDigits="2"
            mode="decimal"
            showButtons
            :inputClass="'text-right'"
            :min="0"
          />
        </div>
        <div class="field col-12 md:col-4">
          <label for=""
            ><strong>IMPORTE EFECTIVO: </strong
            ><span class="p-invalid">*</span></label
          >
          <InputNumber
            inputClass="text-right"
            v-model="importe"
            :minFractionDigits="2"
            mode="decimal"
            :min="0"
            :showButtons="true"
          ></InputNumber>

          <small class="p-invalid" v-if="errors.importe">{{
            errors.importe[0]
          }}</small>
        </div>
        <div class="field col-12">
          <label for=""
            ><strong>POR CONCEPTO DE:</strong>
            <span class="p-invalid">*</span></label
          >
          <Textarea
            v-model="concepto"
            placeholder="Ingrese el concepto del movimiento de caja"
            @keyup.enter="guardarMovCaja"
          />
          <small class="p-invalid" v-if="errors.concepto">{{
            errors.concepto[0]
          }}</small>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>Campos Requeridos: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarModal"
        :disabled="!enviado ? false : true"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class="p-button-primary p-button-lg"
        @click="guardarMovCaja"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
import CajaService from "@/service/CajaService";
import MetodoPagoService from "@/service/MetodoPagoService.js";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    mov_caja: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      data_mov_caja: this.mov_caja,
      enviado: false,
      errors: {},
      tipo_mov: { label: "INGRESO", value: 1 },
      tp_movimientos: [
        { label: "INGRESO", value: 1 },
        { label: "EGRESO", value: 0 },
      ],
      autorizado_por: "FARMACIA",
      importe: 0,
      concepto: null,
      caja_id: null,
      metodo_pagoSelect: 1,
      metodo_pagos: [],
      importe_metodos_pagos: 0,
    };
  },
  cajaService: null,
  metodoPagoService: null,
  created() {
    this.cajaService = new CajaService();
    this.metodoPagoService = new MetodoPagoService();
  },
  mounted() {
    this.getMetodoPagos();
  },
  methods: {
    getMetodoPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
      });
    },
    guardarMovCaja() {
      this.enviado = true;
      let datos = {
        ...this.data_mov_caja,
        tipo_mov: this.tipo_mov.value,
        autorizado_por: this.autorizado_por,
        importe: this.importe,
        concepto: this.concepto,
        caja_id: this.data_mov_caja.id,
        metodo_pago_id: this.metodo_pagoSelect,
        importe_metodos_pagos: this.importe_metodos_pagos,
      };
      this.cajaService.newMovimientoCaja(datos).then((data) => {
        if (Object.defineProperty.hasOwnProperty.call(data, "errors")) {
          this.errors = data.errors;
        } else {
          this.errors = {};
          this.$toast.add({
            severity: "success",
            summary: "Movimiento de Caja",
            detail: data.mensaje,
            life: 3000,
          });
          this.$emit("actualizarListado");
          this.ocultarModal();
        }
        this.enviado = false;
      });
    },
    ocultarModal() {
      this.errors = {};
      this.$emit("closeModal");
      this.tipo_mov = "";
      this.autorizado_por = "";
      this.importe = "";
      this.concepto = "";
    },
  },
  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    mov_caja(value) {
      this.data_mov_caja = value;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>